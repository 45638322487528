import React from "react"
import styles from "./office-hours.module.scss"

export const OfficeHours = ({
  simple_office_hours,
  simple_service_hours,
  location,
}) => {
  if (
    (simple_office_hours.value || simple_service_hours.value) &&
    location === "contact-us"
  )
    return (
      <div className={styles.officeHours}>
        {simple_office_hours.value ? (
          <span>
            <strong>Office Hours: </strong> {simple_office_hours.value}
          </span>
        ) : null}
        {simple_service_hours.value ? (
          <span>
            <strong>Service Hours: </strong> {simple_service_hours.value}
          </span>
        ) : null}
        <hr className={`container ${styles.sectionDivider}`} />
      </div>
    )
  else return null
}

export default OfficeHours
